<template>
  <div class="video">
    <div class="left">
      <template v-if="isUploading">
        <div class="video__loading">
          Загружается {{ currentChunkUploading }} из {{ chunksCount }}
        </div>

        <div class="video__loading">{{ percentCompleted }}%</div>
      </template>

      <template v-else>
        <button
          v-if="recorderStatus === 'inactive' || recorderStatus === ''"
          id="startButton"
          class="button"
          @click="recordingStart"
        >
          Start Record
        </button>
        <button
          v-if="recorderStatus === 'recording'"
          id="stopButton"
          class="button"
          @click="recordingStop"
        >
          Stop
        </button>
      </template>

      <br />
      <br />
      chunksize <input v-model="chunkSize" /> MB
      <br />
      timeSlice <input v-model="timeSlice" /> ms
      <br />
      <br />
      <button class="button" @click="retake">Retake</button>
      <!-- <br />
      <br />
      <a :href="linkToVideo" target="_blank" id="downloadButton" class="button">
        Download first hole video
      </a> -->
      <br />
      <br />
      <a :href="linkToResultVideo" target="_blank" id="downloadButton" class="button">
        Download Merged
      </a>
      <br />
      <small>Правой кнопкой, скопировать ссылку, открыть в новом окне.</small>

      <h3>Status: {{ recorderStatus }} - {{ (currentTimer / 1000 / 60).toFixed(2) }} min</h3>

      <div class="video__logs">
        <div v-for="(item, index) in logs" :key="index" class="video__log">
          {{ item.type }}: {{ item.msg }}
        </div>
      </div>

      <video ref="preview" class="video__player" autoplay playsinline muted></video>
      <vue-plyr :src="stream" />

      <!-- <video
        id="recording"
        type="video/mp4"
        :src="linkToVideo"
        width="1280"
        height="720"
        controls
      ></video> -->
    </div>

    <!-- <div class="right">
      <h2>Recording</h2>
      <video id="recording" width="1280" height="720" controls></video>

      <a :href="linkToVideo" id="downloadButton" class="button">Download {{ linkToVideo }}</a>
    </div> -->

    <div id="log"></div>
  </div>
</template>

<script>
import Axios from 'axios'
import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'
// import axiosRetry from 'axios-retry'
// eslint-disable-next-line
// import * as CryptoJS from 'crypto-js'
// import md5 from 'crypto-js/md5'
import { md5 } from 'js-md5'
// import BMF from 'browser-md5-file'

export default {
  components: {
    VuePlyr
  },

  data() {
    return {
      preview: null,
      RECORDER: null,
      stream: null,
      recorderStatus: '',
      recordedData: [],
      percentCompleted: 0,
      isUploading: false,
      currentChunkUploading: 0,
      chunksCount: 0,
      // linkToVideo: 'https://my.develop.vcv.team/rtplive/tmp-c93f2e0f78d5a5b13a164858fa492417.bin',
      linkToVideo: '',
      linkToResultVideo: '',
      recording: null,
      timer: null,
      currentTimer: 600000,
      logs: [],
      timeSlice: 100, // in ms
      chunkSize: 10 // in MB
    }
  },

  // computed: {
  //   recorderStatus() {
  //     if (this.RECORDER !== null && this.RECORDER.state !== undefined) {
  //       return this.RECORDER.state
  //     }
  //
  //     return ''
  //   }
  // },

  async mounted() {
    this.preview = document.getElementById('preview')
    // this.downloadButton = document.getElementById('downloadButton')
    this.recording = document.getElementById('recording')

    await this.initPreview()
  },

  methods: {
    async initPreview() {
      const constraints = {
        audio: true,
        // video: { facingMode: 'user', width: 1280, height: 720 }
        video: {
          optional: [
            { minWidth: 480 },
            { minWidth: 640 },
            { minWidth: 1024 },
            { minWidth: 1280 },
            { minWidth: 1920 }
          ]

          // width: { min: 1920, ideal: 1920, max: 4096 },
          // height: { min: 1080, ideal: 1080, max: 2160 },
          // bitrate: 10000
        }
      }
      this.stream = await navigator.mediaDevices.getUserMedia(constraints)
      // .then(() => {
      // this.preview.src = URL.createObjectURL(this.blob)

      // console.log(this.preview.captureStream())
      // console.log(this.preview.captureStream())
      // console.log(this.preview.mozCaptureStream)
      // this.preview.captureStream = this.preview.captureStream || this.preview.mozCaptureStream

      // this.preview.onloadedmetadata = () => {
      //   this.preview.play()
      // }
      // })
      this.$refs.preview.srcObject = this.stream
      this.RECORDER = new MediaRecorder(this.stream)

      if (MediaRecorder.isTypeSupported('video/webm')) {
        this.RECORDER = new MediaRecorder(this.stream, { mimeType: 'video/webm' })
      } else {
        this.RECORDER = new MediaRecorder(this.stream, { mimeType: 'video/mp4' })
      }

      // const captureStream = this.preview.captureStream || this.preview.mozCaptureStream

      this.recorderStatus = this.RECORDER.state

      this.RECORDER.ondataavailable = event => {
        this.recordedData.push(event.data)
      }

      this.RECORDER.onstop = async () => {
        // stop video
        this.stream = null
        this.$refs.preview.pause()
        this.$refs.preview.currentTime = 0
        this.appendLog({ type: 'stopevent', msg: `Видео остановлено` })

        const recordedBlob = new Blob(this.recordedData, { type: 'application/json' })
        const url = URL.createObjectURL(recordedBlob)
        const generatedName = this.generateRandomString(8)

        // whole video upload
        // await this.calculateMd5andUpload({ recordedBlob, chunksCount: this.chunksCount })

        this.linkToVideo = url

        // console.log(recordedBlob)
        // console.log(md5(recordedBlob))

        // chunks
        const chunkSize = 1024 * 1024 * this.chunkSize // size of each chunk (1MB * x = MB)
        let start = 0
        let count = 0
        this.chunksCount = Math.ceil(recordedBlob.size / chunkSize)

        while (start < recordedBlob.size) {
          this.currentChunkUploading += 1
          const slicedChunk = recordedBlob.slice(start, start + chunkSize)

          await this.calculateMd5andUpload({
            recordedBlob: slicedChunk,
            generatedName,
            fileCount: count,
            chunksCount: this.chunksCount
          })

          start += chunkSize
          count += 1
        }
      }
    },

    generateRandomString(
      length,
      characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    ) {
      let result = ''
      const charactersLength = characters.length
      let counter = 0

      while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
        counter += 1
      }

      return result
    },

    async calculateMd5andUpload({ recordedBlob, generatedName, fileCount, chunksCount }) {
      return await new Promise(resolve => {
        let reader = new FileReader()
        // reader.readAsBinaryString(recordedBlob) // 1
        reader.readAsArrayBuffer(recordedBlob)
        // reader.readAsDataURL(recordedBlob)
        // reader.readAsText(recordedBlob)
        reader.onloadend = async () => {
          // const hash = md5(reader.result).toString()
          const hash = md5(reader.result)

          // console.log(hash, jhash)

          await this.uploadFile({ recordedBlob, generatedName, fileCount, chunksCount, md5: hash })
          resolve(true)
        }
      })
    },

    async uploadFile({ recordedBlob, generatedName, fileCount, chunksCount, md5 }) {
      // Axios retry
      // axiosRetry(Axios, { retries: 3, retryDelay: axiosRetry.exponentialDelay })

      // FormData for Blob is mandatory
      let data = new FormData()

      if (generatedName !== undefined) {
        data.append('name', generatedName)
      }
      data.append('video', recordedBlob)
      if (fileCount !== undefined) {
        data.append('file_count', fileCount)
      }
      data.append('chunks_count', chunksCount)
      data.append('crc', md5)

      this.isUploading = true

      await Axios.post('https://neochar.com/index.php', data, {
        headers: {
          // Authorization: `Bearer bb4a86c56b33abb7a911dddcd86ff3fd`
          // 'Content-Type': 'multipart/form-data'
          // 'Content-Type': 'multipart/mixed'
          // 'Content-Type': 'alternative'
          // 'Content-Type': 'application/json'
          // 'Content-Type': 'application/x-www-form-urlencoded'
        },
        onUploadProgress: progressEvent => {
          this.percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        }
      })
        .then(response => {
          console.log('sent', response)
          // alert('Отправилось! ' + recordedBlob.size / 1000000)
          this.percentCompleted = 0
          this.linkToResultVideo = response.data.url

          if (this.currentChunkUploading >= this.chunksCount + 1) {
            this.currentChunkUploading = 0
            this.chunksCount = 0
          }

          if (fileCount === 'all') {
            this.linkToVideo = response.data.url

            this.appendLog({
              type: 'original uploaded',
              msg: `${(recordedBlob.size / 1000000).toFixed(3)}МB - F:${md5} - B:${
                response.data.crc
              }`
            })
          } else {
            this.appendLog({
              type: 'chunk uploaded',
              msg: `#${fileCount}, ${(recordedBlob.size / 1000000).toFixed(3)}МB - F:${md5} - B:${
                response.data.crc
              }`
            })
          }

          if (response.data.result_crc !== undefined) {
            this.appendLog({
              type: 'Результат',
              msg: `#merged - B:${response.data.result_crc}`
            })
          }
        })
        .catch(error => {
          console.log('not sent')

          // alert('Не отправилось! ' + recordedBlob.size / 1000000)

          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data)
            console.log(error.response.status)
            console.log(error.response.headers)

            this.appendLog({
              type: 'error_response',
              msg: `${error.response.data} ${error.response.status} ${error.response.headers}`
            })
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request)
            this.appendLog({ type: 'error_request', msg: error.request })
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message)
            this.appendLog({ type: 'error_message', msg: error.message })
          }
          console.log(error.config)
          this.appendLog({ type: 'error_config', msg: error.config })
        })
        .finally(() => {
          this.recordedData = []
          this.isUploading = false
        })
    },

    saveFile(blob, filename) {
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, filename)
      } else {
        const a = document.createElement('a')
        document.body.appendChild(a)
        const url = window.URL.createObjectURL(blob)
        a.href = url
        a.download = filename
        a.click()
        setTimeout(() => {
          window.URL.revokeObjectURL(url)
          document.body.removeChild(a)
        }, 0)
      }
    },

    async recordingStart() {
      if (this.stream === null) {
        await this.initPreview()
      }

      this.RECORDER.start(this.timeSlice)
      this.startTimer()
      this.recorderStatus = this.RECORDER.state
      this.appendLog({ type: 'recorder', msg: this.RECORDER.state })
    },

    recordingStop() {
      this.RECORDER.stop()
      this.stopTimer()
      this.recorderStatus = this.RECORDER.state
      this.appendLog({ type: 'recorder', msg: this.RECORDER.state })
    },

    retake() {
      this.stopTimer()
      // this.RECORDER.stop()
      // this.recorderStatus = this.RECORDER.state
      this.initPreview()
    },

    appendLog({ type, msg }) {
      this.logs.push({ type, msg })
    },

    startTimer() {
      this.timer = setInterval(() => {
        this.currentTimer -= 1000

        if (this.currentTimer === 0) {
          this.recordingStop()
        }
      }, 1000)
    },

    stopTimer() {
      clearInterval(this.timer)
      this.currentTimer = 600000
      this.timer = null
    }

    // async wait(delayInMS) {
    //   return new Promise(resolve => setTimeout(resolve, delayInMS))
    // },
    //
    // startRecordingg(stream, lengthInMS) {
    //   console.log(stream)
    //   let recorder = new MediaRecorder(stream)
    //   let data = []
    //
    //   recorder.ondataavailable = event => {
    //     data.push(event.data)
    //   }
    //   recorder.start()
    //   this.log(recorder.state + ' for ' + lengthInMS / 1000 + ' seconds...')
    //
    //   console.log(data)
    //
    //   let stopped = new Promise((resolve, reject) => {
    //     recorder.onstop = resolve
    //     recorder.onerror = event => reject(event.name)
    //   })
    //
    //   let recorded = this.wait(lengthInMS).then(
    //     () => recorder.state == 'recording' && recorder.stop()
    //   )
    //
    //   return Promise.all([stopped, recorded]).then(() => data)
    // },
    //
    // stopRecord() {
    //   this.stop(this.preview.srcObject)
    // },
    //
    // stop(stream) {
    //   stream.getTracks().forEach(track => track.stop())
    // }
  }
}
</script>

<style lang="scss" scoped>
.video {
  &__player {
    max-width: 100%;
    width: 1280px;
    height: 720px;
    border: 1px solid #ccc;
  }

  &__btn {
    width: 250px;
    height: 60px;
    background: #166495;
    outline: none;
    border: none;
    border-radius: 3px;
    font-size: 22px;
    color: #fff;

    &:active {
      background: lighten(#166495, 10%);
    }
  }

  &__logs {
    margin: 15px;
  }
}
</style>

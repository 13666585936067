<template>
  <div class="charts">
    <div class="charts__wrapper">
      <div class="charts__title">Doughnut</div>
      <div class="charts__chart">
        <apexchart type="donut" :series="donutSeries" :options="donutOptions" />
      </div>
    </div>

    <div class="charts__wrapper">
      <div class="charts__title">Bar</div>
      <div class="charts__chart">
        <apexchart type="bar" :series="barSeries" :options="barOptions" />
      </div>
    </div>

    <div class="charts__wrapper">
      <div class="charts__title">Combo</div>
      <div class="charts__chart line">
        <apexchart type="bar" :series="comboSeries" :options="comboOptions" />
      </div>
    </div>

    <div class="charts__wrapper">
      <div class="charts__title">Forecast</div>
      <div class="charts__chart line">
        <apexchart type="line" :series="foreSeries" :options="foreOptions" />
      </div>
    </div>

    <div class="charts__wrapper">
      <div class="charts__title">MultiAxis</div>
      <div class="charts__chart line">
        <apexchart type="line" :series="multiSeries" :options="multiOptions" />
      </div>
    </div>

    <div class="charts__wrapper">
      <div class="charts__title">Radar chart</div>
      <div class="charts__chart line">
        <apexchart type="radar" :series="radarSeries" :options="radarOptions" />
      </div>
    </div>

    <div class="charts__wrapper">
      <div class="charts__title">Visyak</div>
      <div class="charts__chart line">
        <apexchart type="bar" :series="visyakSeries" :options="visyakOptions" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      donutSeries: [16, 72, 12],
      donutOptions: {
        labels: ['Hight income', 'Middle income', 'Low income'],
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val
          }
        }
      },

      barSeries: [
        {
          name: 'Other',
          data: [10, 20, 5]
        },
        {
          name: 'Pedestrians',
          data: [30, 20, 15]
        },
        {
          name: 'Cyclist',
          data: [10, 10, 5]
        },
        {
          name: 'Motorized 2-3 wheels',
          data: [20, 20, 15]
        },
        {
          name: 'Car occupants',
          data: [30, 30, 60]
        }
      ],
      barOptions: {
        labels: ['High income', 'Middle income', 'Low income'],
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val + '%'
          }
        },
        chart: {
          height: 350,
          stacked: true,
          stackType: '100%'
        }
      },

      comboSeries: [
        {
          name: 'Jelly Beans, ton',
          type: 'column',
          data: [200, 220, 230, 200, 300, 400, 200, 700, 350, 250, 500]
        },
        {
          name: 'Jelly Bears, ton',
          type: 'column',
          data: [200, 220, 230, 200, 300, 400, 200, 700, 350, 250, 500]
        },
        {
          name: '$ value, million',
          data: [300, 300, 330, 230, 350, 450, 250, 800, 400, 350, 600],
          type: 'line'
        }
      ],

      comboOptions: {
        labels: [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'November',
          'December'
        ],
        chart: {
          stacked: true
        },
        yaxis: {
          min: 0,
          max: 1400
        },
        stroke: {
          width: [0, 2, 5],
          curve: 'smooth'
        }
      },

      foreSeries: [
        {
          name: 'Sales',
          data: [4, 3, 10, 9, 29, 19, 22, 9, 12, 7, 19, 5, 13, 9, 17, 2, 7, 5]
        }
      ],

      foreOptions: {
        chart: {
          height: 350
        },
        forecastDataPoints: {
          count: 7
        },
        stroke: {
          width: 5,
          curve: 'smooth'
        },
        xaxis: {
          type: 'datetime',
          categories: [
            '1/11/2000',
            '2/11/2000',
            '3/11/2000',
            '4/11/2000',
            '5/11/2000',
            '6/11/2000',
            '7/11/2000',
            '8/11/2000',
            '9/11/2000',
            '10/11/2000',
            '11/11/2000',
            '12/11/2000',
            '1/11/2001',
            '2/11/2001',
            '3/11/2001',
            '4/11/2001',
            '5/11/2001',
            '6/11/2001'
          ],
          tickAmount: 10,
          labels: {
            formatter: function (value, timestamp, opts) {
              return opts.dateFormatter(new Date(timestamp), 'dd MMM')
            }
          }
        },
        annotations: {
          yaxis: [
            {
              y: 7,
              borderColor: '#00E396',
              label: {
                borderColor: '#00E396',
                style: {
                  color: '#fff',
                  background: '#00E396'
                },
                text: 'Y-axis annotation'
              }
            }
          ],
          xaxis: [
            {
              x: new Date('10/11/2000').getTime(),
              borderColor: '#00E396',
              label: {
                borderColor: '#00E396',
                style: {
                  color: '#fff',
                  background: '#00E396'
                },
                text: 'X-axis annotation'
              }
            }
          ]
        }
      },

      multiSeries: [
        {
          name: 'Series A',
          data: [1.4, 2, 2.5, 1.5, 2.5, 2.8, 3.8, 4.6]
        },
        {
          name: 'Series B',
          data: [20, 29, 37, 36, 44, 45, 50, 58]
        }
      ],

      multiOptions: {
        chart: {
          height: 350,
          type: 'line',
          stacked: false
        },
        dataLabels: {
          enabled: false
        },
        colors: ['#FF1654', '#247BA0'],
        stroke: {
          width: [4, 4]
        },
        plotOptions: {
          bar: {
            columnWidth: '20%'
          }
        },
        xaxis: {
          categories: [2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016]
        },
        yaxis: [
          {
            axisTicks: {
              show: true
            },
            axisBorder: {
              show: true,
              color: '#FF1654'
            },
            labels: {
              style: {
                colors: '#FF1654'
              }
            },
            title: {
              text: 'Series A',
              style: {
                color: '#FF1654'
              }
            }
          },
          {
            opposite: true,
            axisTicks: {
              show: true
            },
            axisBorder: {
              show: true,
              color: '#247BA0'
            },
            labels: {
              style: {
                colors: '#247BA0'
              }
            },
            title: {
              text: 'Series B',
              style: {
                color: '#247BA0'
              }
            }
          }
        ],
        tooltip: {
          shared: false,
          intersect: true,
          x: {
            show: false
          }
        },
        legend: {
          horizontalAlign: 'left',
          offsetX: 40
        }
      },

      radarSeries: [
        {
          name: 'Series 1',
          data: [80, 50, 30, 40, 100, 20]
        }
      ],

      radarOptions: {
        chart: {
          height: 350,
          type: 'radar'
        },
        title: {
          text: 'Basic Radar Chart'
        },
        xaxis: {
          categories: ['January', 'February', 'March', 'April', 'May', 'June']
        }
      },

      visyakSeries: [
        {
          name: 'Materials',
          data: [40, 43, 0, 43]
        },
        {
          name: 'Labor',
          data: [45, 10, 0, 10]
        },
        {
          name: 'Overhead',
          data: [15, 12, 0, 12]
        },
        {
          label: 'Additionall cost',
          data: [0, 0, 10, 10]
        }
      ],
      // visyakSeries: [
      //   {
      //     data: [
      //       { x: 'Materials', y: [0, 40] },
      //       { x: 'Labor', y: [0, 43] },
      //       { x: 'Overhead', y: [0, 0] },
      //       { x: 'Additionall cost', y: [0, 43] }
      //     ]
      //   },
      //   {
      //     data: [
      //       { x: 'Materials', y: [40, 45] },
      //       { x: 'Labor', y: [43, 53] },
      //       { x: 'Overhead', y: [0, 0] },
      //       { x: 'Additionall cost', y: [43, 53] }
      //     ]
      //   },
      //   {
      //     data: [
      //       { x: 'Materials', y: [45, 100] },
      //       { x: 'Labor', y: [53, 65] },
      //       { x: 'Overhead', y: [0, 0] },
      //       { x: 'Additionall cost', y: [53, 65] }
      //     ]
      //   },
      //   {
      //     data: [
      //       { x: 'Materials', y: [0, 0] },
      //       { x: 'Labor', y: [0, 0] },
      //       { x: 'Overhead', y: [65, 75] },
      //       { x: 'Additionall cost', y: [0, 0] }
      //     ]
      //   }
      // ],
      visyakOptions: {
        labels: [
          ['Cost in high-cost', 'country'],
          ['Cost in low-cost', 'country'],
          ['Additionall cost:', 'supply chain,', 'transactional costs'],
          ['Total costs in', 'low-cost country']
        ],
        dataLabels: {
          enabled: true
        },
        legend: {
          onItemClick: {
            toggleDataSeries: false
          }
        },
        annotations: {
          yaxis: [
            {
              y: 75,
              y2: 100,
              opacity: 0.4,
              borderColor: '#8871d4',
              fillColor: '#8871d4',
              label: {
                borderColor: '#8871d4',
                style: {
                  color: '#fff',
                  background: '#8871d4',
                  fontSize: '13px',
                  padding: {
                    left: 10,
                    right: 10,
                    top: 3,
                    bottom: 5
                  }
                },
                text: 'Difference'
              }
            },
            {
              y: 65,
              y2: 75,
              borderColor: '#4b8faf',
              fillColor: '#4b8faf',
              label: {
                borderColor: '#4b8faf',
                style: {
                  color: '#fff',
                  background: '#4b8faf',
                  fontSize: '13px',
                  padding: {
                    left: 10,
                    right: 10,
                    top: 3,
                    bottom: 5
                  }
                },
                text: 'Additionall cost: supply chain, transactional costs'
              }
            }
          ]
        },
        plotOptions: {
          bar: {
            horizontal: false
          }
        },
        chart: {
          // height: 350,
          stacked: true
        },
        yaxis: {
          max: 100
        },
        xaxis: {
          labels: {
            rotate: 0,
            hideOverlappingLabels: false
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.charts {
  &__wrapper {
    margin-bottom: 30px;
  }

  &__chart {
    max-width: 500px;

    &.line {
      max-width: 800px;
    }
  }
}
</style>

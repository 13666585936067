<template>
  <div class="video">
    <div class="left">
      <div class="video__text">Как на продакшене сейчас</div>

      <div class="video__timer">{{ timerSeconds }}</div>

      <button
        v-if="recorderStatus === 'inactive' || recorderStatus === ''"
        id="startButton"
        class="button"
        @click="recordingStart"
      >
        Start Record
      </button>
      <button
        v-if="recorderStatus === 'inactive' || recorderStatus === ''"
        id="startButton"
        class="button"
        @click="recordingStart(30)"
      >
        Start Record 30 sec
      </button>
      <button
        v-if="recorderStatus === 'recording'"
        id="stopButton"
        class="button"
        @click="recordingStop"
      >
        Stop
      </button>
      <br />
      <br />
      <button class="button" @click="retake">Retake</button>
      <br />
      <br />
      <a :href="linkToVideo" target="_blank" id="downloadButton" class="button">Download</a>
      <br />
      <small>Правой кнопкой, скопировать ссылку, открыть в новом окне.</small>

      <h3>Status: {{ recorderStatus }}</h3>
      <video ref="preview" class="video__player" autoplay playsinline muted></video>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      preview: null,
      timerInterval: null,
      timerSeconds: 0,
      RECORDER: null,
      stream: null,
      recorderStatus: '',
      recordedData: [],
      linkToVideo: 'https://my.develop.vcv.team/rtplive/tmp-c93f2e0f78d5a5b13a164858fa492417.bin',
      recording: null,
      constraints: {
        audio: true,
        // video: { facingMode: 'user', width: 1280, height: 720 }
        video: {
          optional: [
            { minWidth: 480 },
            { minWidth: 640 },
            { minWidth: 1024 },
            { minWidth: 1280 },
            { minWidth: 1920 }
          ],

          // width: { min: 1920, ideal: 1920, max: 4096 },
          // height: { min: 1080, ideal: 1080, max: 2160 },
          bitrate: 4000
        }
      }
    }
  },

  async mounted() {
    this.preview = document.getElementById('preview')
    // this.downloadButton = document.getElementById('downloadButton')
    this.recording = document.getElementById('recording')

    await this.initPreview()
  },

  destroyed() {
    if (this.RECORDER.state === 'recording' || this.RECORDER.state === 'paused') {
      this.RECORDER.stop()
    }
  },

  methods: {
    async initPreview() {
      this.stream = await navigator.mediaDevices.getUserMedia(this.constraints)
      this.$refs.preview.srcObject = this.stream
      this.RECORDER = new MediaRecorder(this.stream)

      if (MediaRecorder.isTypeSupported('video/webm')) {
        this.RECORDER = new MediaRecorder(this.stream, { mimeType: 'video/webm' })
      } else {
        this.RECORDER = new MediaRecorder(this.stream, { mimeType: 'video/mp4' })
      }

      this.recorderStatus = this.RECORDER.state

      this.RECORDER.ondataavailable = event => {
        this.recordedData.push(event.data)
      }

      this.RECORDER.onstop = () => {
        console.log('stp')
        const recordedBlob = new Blob(this.recordedData, { type: this.RECORDER.mimeType })
        // const recordedBlob = new Blob(this.recordedData, { type: 'video/mp4' })

        const url = URL.createObjectURL(recordedBlob)

        this.saveFile(recordedBlob)

        this.linkToVideo = url

        // FormData for Blob is mandatory
        // let data = new FormData()
        // data.append('video[source]', recordedBlob)

        // Axios.post('https://test03.vcv.team/api/1.0/video/save-video', data, {
        //   headers: { Authorization: `Bearer 4161e9bfad1119cc24647f6125358eb9` }
        // }).then(response => {
        //   this.linkToVideo = response.data.data[0].source
        // })
      }
    },

    saveFile(blob, filename) {
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, filename)
      } else {
        const a = document.createElement('a')
        document.body.appendChild(a)
        const url = window.URL.createObjectURL(blob)
        a.href = url
        a.download = filename
        a.click()
        setTimeout(() => {
          window.URL.revokeObjectURL(url)
          document.body.removeChild(a)
        }, 0)
      }
    },

    recordingStart(secondsRemain) {
      this.startTimer(secondsRemain)
      this.RECORDER.start()
      this.recorderStatus = this.RECORDER.state
    },

    recordingStop() {
      this.RECORDER.stop()
      this.recorderStatus = this.RECORDER.state
      this.clearTimer()
    },

    retake() {
      // this.RECORDER.stop()
      // this.recorderStatus = this.RECORDER.state
      this.initPreview()
    },

    startTimer(seconds = null) {
      this.timerInterval = setInterval(() => {
        this.timerSeconds += 1

        if (seconds !== null && this.timerSeconds >= seconds) {
          this.recordingStop()
        }
      }, 1000)
    },

    clearTimer() {
      clearInterval(this.timerInterval)
      this.timerInterval = null
      this.timerSeconds = 0
    }
  }
}
</script>

<style lang="scss" scoped>
.video {
  &__text {
    margin-bottom: 20px;
  }

  &__player {
    transform: scaleX(-1);
    width: 1280px;
    height: 720px;
    border: 1px solid #ccc;
  }

  &__btn {
    width: 250px;
    height: 60px;
    background: #166495;
    outline: none;
    border: none;
    border-radius: 3px;
    font-size: 22px;
    color: #fff;

    &:active {
      background: lighten(#166495, 10%);
    }
  }
}
</style>

import Vue from 'vue'
import VueRouter from 'vue-router'
import VideoTest from '../views/VideoTest'
import IntegrationEvent from '../views/IntegrationEvent'
import Charts from '../views/Charts'
import ApexCharts from '../views/ApexCharts'
import Video1 from '../views/Video1'
import Video2 from '../views/Video2'
import Video3 from '../views/Video3'
import Video4 from '../views/Video4'
// const GoogleMeet = () => import('../views/GoogleMeet')
// const GoogleMeet2 = () => import('../views/GoogleMeet2')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    // redirect: { name: 'video4' },
    name: 'VideoTest',
    component: VideoTest
  },
  {
    path: '/video1',
    name: 'video1',
    component: Video1
  },
  {
    path: '/video2',
    name: 'video2',
    component: Video2
  },
  {
    path: '/video3',
    name: 'video3',
    component: Video3
  },
  {
    path: '/video4',
    name: 'video4',
    component: Video4
  },
  {
    path: '/integration-event',
    name: 'IntegrationEvent',
    component: IntegrationEvent
  },
  {
    path: '/charts',
    name: 'charts',
    component: Charts
  },
  {
    path: '/apex-charts',
    name: 'apex-charts',
    component: ApexCharts
  }
  // {
  //   path: '/meet',
  //   name: 'GoogleMeet',
  //   component: GoogleMeet
  // },
  // {
  //   path: '/meet2',
  //   name: 'GoogleMeet2',
  //   component: GoogleMeet2
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

<template>
  <div class="event">
    <button @click="click">Нажми меня чтобы отправить событие</button>
  </div>
</template>

<script>
export default {
  methods: {
    click() {
      window.parent.postMessage({ sender: 'vcv_integration' }, '*')
    }
  }
}
</script>

<template>
  <div id="app">
    <br />
    <br />
    <br />
    <!-- <div id="nav">
      <router-link to="/video1">Video1</router-link> |
      <router-link to="/video2">Video2</router-link> |
      <router-link to="/video3">Video3</router-link> |
      <router-link to="/video4">Video4</router-link> |
      <router-link to="/integration-event">Event</router-link> |
    </div>
    <router-view /> -->

    <video4 />
  </div>
</template>

<script>
import Video4 from '@/views/Video4.vue'

export default {
  components: {
    Video4
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>

<template>
  <div class="charts">
    <div class="charts__wrapper">
      <div class="charts__title">Doughnut</div>
      <div class="charts__chart">
        <doughnut :chart-data="chartData" :chart-options="chartOptions" />
      </div>
    </div>

    <div class="charts__wrapper">
      <div class="charts__title">Bar</div>
      <div class="charts__chart">
        <bar :chart-data="chartDataBar" :chart-options="chartOptionsBar" />
      </div>
    </div>

    <div class="charts__wrapper">
      <div class="charts__title">Lines</div>
      <div class="charts__chart line">
        <chart-line :chart-data="chartDataLine" :chart-options="chartOptions" />
      </div>
    </div>

    <div class="charts__wrapper">
      <div class="charts__title">Combo</div>
      <div class="charts__chart line">
        <bar :chart-data="chartDataCombo" :chart-options="chartOptionsBar" />
      </div>
    </div>

    <div class="charts__wrapper">
      <div class="charts__title">Bar s visyakami</div>
      <div class="charts__chart line">
        <bar :chart-data="chartDataBar2" :chart-options="chartOptionsBar" />
      </div>
    </div>

    <!-- <div class="charts__wrapper">
      <div class="charts__title">Custom line</div>
      <div class="charts__chart line">
        <custom-line :chart-data="chartDataLine" :chart-options="chartOptionsBar" />
      </div>
    </div> -->
  </div>
</template>

<script>
import { Doughnut, Bar, Line } from 'vue-chartjs/legacy'
// import { generateChart } from 'vue-chartjs'
// import { LineController } from 'chart.js'

// class LineWithLineController extends LineController {
// draw() {
//   // Call bubble controller method to draw all the points
//   super.draw(arguments)
//   // Now we can do some custom drawing for this dataset. Here we'll draw a red box around the first point in each dataset
//   const meta = this.getMeta()
//   const pt0 = meta.data[0]
//   const { x, y } = pt0.getProps(['x', 'y'])
//   const { radius } = pt0.options
//   const ctx = this.chart.ctx
//   ctx.save()
//   ctx.strokeStyle = 'red'
//   ctx.lineWidth = 1
//   ctx.strokeRect(x - radius, y - radius, 2 * radius, 2 * radius)
//   ctx.restore()
// }
// }
// const CustomLine = generateChart('custom-line', 'line', LineWithLineController)

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
  BarElement,
  LinearScale,
  LineElement,
  PointElement
} from 'chart.js'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
  BarElement,
  LinearScale,
  LineElement,
  PointElement
)

export default {
  components: { Doughnut, Bar, ChartLine: Line },

  data() {
    return {
      chartData: {
        datasets: [
          {
            backgroundColor: ['#41B883', '#E46651', '#00D8FF'],
            data: [12, 16, 72]
          },
          {
            backgroundColor: ['#41B883', '#E46651', '#00D8FF'],
            data: [8, 12, 80]
          },
          {
            backgroundColor: ['#41B883', '#E46651', '#00D8FF'],
            data: [1, 47, 52]
          }
        ]
      },

      chartDataBar: {
        labels: ['Low-income', 'Middle-income', 'High-income'],
        datasets: [
          {
            label: 'Other',
            backgroundColor: '#b3b3b3',
            data: [10, 20, 5],
            stack: 'Stack 0'
          },
          {
            label: 'Pedestrians',
            backgroundColor: '#a8e5ef',
            data: [35, 20, 15],
            stack: 'Stack 0'
          },
          {
            label: 'Cyclist',
            backgroundColor: '#03a8f3',
            data: [10, 15, 10],
            stack: 'Stack 0'
          },
          {
            label: 'Motorized 2-3 wheels',
            backgroundColor: '#2351fe',
            data: [20, 25, 15],
            stack: 'Stack 0'
          },
          {
            label: 'Cars',
            backgroundColor: '#2647af',
            data: [25, 20, 55],
            stack: 'Stack 0'
          }
        ]
      },

      chartOptionsBar: {
        responsive: true,
        scales: {
          x: { stacked: true },
          y: { stacked: true }
        },
        plugins: {
          title: {
            display: true,
            text: 'Custom BarChart Title'
          }
        }
      },

      chartDataLine: {
        labels: ['21', '22', '23', '24', '25', '26'],
        datasets: [
          {
            label: 'Data One',
            backgroundColor: 'blue',
            borderColor: 'blue',
            data: [0, 135, 130, 120, 140, 125]
          },
          {
            label: 'Data Two',
            backgroundColor: 'red',
            borderColor: 'red',
            data: [0, 110, 125, 135, 140, 120]
          },
          {
            label: 'Data Three',
            backgroundColor: 'green',
            borderColor: 'green',
            data: [0, 110, 115, 120, 130, 135]
          },
          {
            label: 'Data Four',
            backgroundColor: 'grey',
            borderColor: 'grey',
            data: [0, 100, 100, 110, 120, 130]
          }
        ]
      },

      chartDataCombo: {
        labels: [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'November',
          'December'
        ],
        datasets: [
          {
            label: 'Sales volume, ton',
            backgroundColor: '#b3b3b3',
            order: 2,
            data: [2200, 1800, 2000, 1700, 900, 1000, 800, 700, 600, 400, 500],
            stack: 'Stack 0'
          },
          {
            label: 'Sales volume, ton',
            backgroundColor: '#2647af',
            order: 2,
            data: [200, 800, 1000, 700, 200, 100, 200, 700, 600, 400, 500],
            stack: 'Stack 0'
          },
          {
            label: 'Whosale price, $ per ton',
            borderColor: 'green',
            data: [400, 600, 800, 1500, 1600, 1500, 1700, 1600, 2000, 2100, 2200],
            order: 1,
            type: 'line'
          }
        ]
      },

      chartDataBar2: {
        labels: [
          'Cost in high-cost country',
          'Cost in low-cost country',
          'Additionall cost: supply chain, transactional costs',
          'Total costs in low-cost country'
        ],
        datasets: [
          {
            label: 'Materials',
            backgroundColor: '#b3b3b3',
            data: [40, 43, 0, 43],
            stack: 'Stack 0'
          },
          {
            label: 'Labor',
            backgroundColor: '#2647af',
            data: [45, 10, 0, 10],
            stack: 'Stack 0'
          },
          {
            label: 'Overhead',
            backgroundColor: '#aec2fe',
            data: [0, 0, [65, 75], 12],
            stack: 'Stack 0'
          },
          {
            label: 'Difference',
            backgroundColor: '#a8e5ef',
            data: [15, 12, 0, 10],
            stack: 'Stack 0'
          }
        ]
      },

      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          title: {
            display: true,
            text: 'Custom Chart Title'
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.charts {
  &__wrapper {
    margin-bottom: 30px;
  }

  &__chart {
    max-width: 400px;

    &.line {
      max-width: 800px;
    }
  }
}
</style>
